.wallpaper {
  background: url("/home/home.jpg") center center;
  height: 596px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ftco-section {
  margin-top: -5em;
}

.textWallpaper {
  color: $white;
}

h3 {
  cursor: default;
}

#slogan {
  font-size: 4em;
  color: white;
  text-shadow: 2px 0 0 $gray-medium;
  margin-left: 3em;


  @media (max-width: 991px) {
		font-size: 3.2em;
    margin-left: 2.2em;
  }

  @media (max-width: 768px) {
		font-size: 2.9em;
    margin-left: 1.5em;
  }

  @media (max-width: 696px) {
    font-size: 2.5em;
    margin-left: 0;
  }

}

.slogan {
  font-family: 'Dancing Script', cursive;
  font-size: 1.5em;
}

.none {
  visibility: hidden;
}


/*
.carousel-item {
  height: 500px;
  @media (max-width: 840px) {
		height: 400px;
  }
  @media (max-width: 739px) {
    height: 300px;
  }

  @media (max-width: 696px) {
    height: 250px;
  }
}

.carousel-item img {
    height: 500px;

	  @media (max-width: 840px) {
			height: 400px;
	  }
	  @media (max-width: 739px) {
	    height: 300px;
	  }

	  @media (max-width: 696px) {
	    height: 250px;
	  }
}
*/
.cardHome {
  width: 20% !important;
  align-items: center !important;

  &:hover {
    background-color: $primary-green;

    .cardHomeContainer {
      svg {
        fill: $white;
      }

      h6 {
        color: $white;
      }
      p{
        color: $white;
      }
    }

    .heartCard {
      svg {
        fill: $primary-green;
        stroke: $white;
      }
    }
  }


    @media (max-width: 991px) {
  	   width: 20% !important;
    }

    @media (max-width: 768px) {
  		width: 45% !important;
    }

    @media (max-width: 696px) {
      width: 95% !important;
    }
}

svg {
  margin-left: 35%;
}


.homeIcon {
  width: 50px;
  margin-left: 35%;
}

.homeInfo {
  padding-bottom: 15px;
  li {
    margin: 5px;
  }
  @media (min-width: 480px) {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 30%;
      }
  }

}
.cardHomeContainer {
  h6 {
    text-transform: capitalize;
    //font-weight: bold;
    color: $gray-dark;
  }
  p{
    color: $gray-light;
    font-size: 15px;
  }
}

.group-container {
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  padding-bottom: 15px;
  li {
    cursor: pointer;
		border-bottom: 2px solid $gray-light-bg;
    padding: 0 20px;
    &:hover {
      color: $primary-orange;
  		border-bottom: 2px solid $primary-orange;
    }
    &:active {
      color: $white;
      background-color: $primary-orange;
    }

    @media (max-width: 1024px) {
      padding: 5px 10px;
    }

  }

  @media (max-width: 680px) {
    display: none;
  }
}

.titleCard {
  padding-left: 20px;

  &.artcles {
    color: $gray-dark;
    background-color: $primary-yellow;
  }

  &.happy {
    color: $white;
    background-color: $primary-green;
  }
}

.homeCard {
  font-size: .9em;
  margin: 15px;


  @media(min-width: 768px) {
    width: 45%;
  }
  @media(min-width: 990px) {
    width: 30%;
  }

  .bt-crd {
    background-color: $gray-light;

    &:hover {
      background-color: $gray-eighty;
      color: $white;

      a {
        color: $black;
      }
    }

    a {
      color: $white;
    }
  }
}

.cardTestimonial {
    border: 1px solid $primary-green;
}


.floatRight {
  float: right !important;
  top: 0;
}

.important {
  background-color: $gray-eighty;
  padding: 10px;
}

.homeCardTest {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.cardTest {

  @media (min-width: 760px) {
    width: 45%;
    margin: 2.5%;
  }
}

// About
.wallpaperAbout {
  background: url("/about/about.jpg") center center;
  height: 596px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .textAbout {
    color: $white;
  }

}


#about {
  font-size: 4em;
  color: white;
  text-shadow: 2px 0 0 $gray-medium;


  @media (max-width: 991px) {
		font-size: 3.2em;
  }

  @media (max-width: 768px) {
		font-size: 2.9em;
  }

  @media (max-width: 696px) {
    font-size: 2.5em;
  }

}



// Contact
.wallpaperContact {
  background: url("/about/contact.jpg") center center;
  height: 596px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .textContact {
    color: $white;
  }

}


#contact {
  font-size: 4em;
  color: white;
  text-shadow: 2px 0 0 $gray-medium;


  @media (max-width: 991px) {
		font-size: 3.2em;
  }

  @media (max-width: 768px) {
		font-size: 2.9em;
  }

  @media (max-width: 696px) {
    font-size: 2.5em;
  }

}
