.no-click {
    pointer-events: none;
}

.wallpaperTestimonial {
  background: url("/about/testimonials.jpg") center center;
  height: 350px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


#testimonials {
  font-size: 4em;
  color: $white;
  text-shadow: 2px 0 0 $gray-medium;


  @media (max-width: 991px) {
		font-size: 3.2em;
  }

  @media (max-width: 768px) {
		font-size: 2.9em;
  }

  @media (max-width: 696px) {
    font-size: 2.5em;
  }

}


.cardTestimonial {
  margin-bottom: 20px;
}

.testimonials {
    @media (min-width: 760px) {
      width: 60%;
    }
}

.personRate {
  color: $primary-green;
}

.rating {
  font-size: 25px;
}

.btn-testimonial {
  margin-right: 5px;
}



/// TESTIMONIALS


.wallpaperFAQs {
  background: url("/about/questions.jpg") center center;
  height: 350px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


#faqs {
  font-size: 4em;
  color: $white;
  text-shadow: 2px 0 0 $gray-medium;


  @media (max-width: 991px) {
		font-size: 3.2em;
  }

  @media (max-width: 768px) {
		font-size: 2.9em;
  }

  @media (max-width: 696px) {
    font-size: 2.5em;
  }

  @media (max-width: 464px) {
    margin-left: 15%;
  }

}

.faqs {
    @media (min-width: 760px) {
      width: 60%;
    }
}
