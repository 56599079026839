.question {
  background-color: $gray-dark-bg;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid $gray-medium;

  @media (max-width: 991px) {
    padding: 8px;
    .container {
      padding: 0;
    }
  }
  @media (max-width: 480px) {
    padding: 8px 12px;
  }

}

.spaceBotton {
  margin-bottom: 10px;
}


//************* LIST TERMINS AND CONDITIONS ********************
.list-yes {
  list-style-type: circle;
  list-style-position: outside;
  padding-left: 15px;
}

.sublist-yes {
  list-style-type: decimal;
  list-style-position: outside;
  padding-left: 20px;
}
