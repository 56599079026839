
.box-simple .icon-outlined {
  color: $primary-green;
  border: 1px solid $primary-green;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.icon-outlined {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  font-size: 1.4rem;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-bottom: 20px;
}
