header {
  //padding: 10px 32px 10px;
  //margin-bottom: 25px;
  //box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.033);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  //animation: slideUp 300ms linear;
  -webkit-transition: background-color .5s ease; /* For Safari 3.1 to 6.0 */
  transition: background-color .5s ease;

  .container {
    display: flex;
    display: -webkit-flex;
    max-width: 85%;
    margin: 0 auto;
    padding: 0 32px;

    .alignMenu {
      margin-top: auto;
      margin-left: auto;
    }
  }

  .desap {
   -moz-animation: slide 1s ease 3.5s forwards;
   -webkit-animation: slide 1s ease 3.5s forwards;
   -o-animation: slide 1s ease 3.5s forwards;
   -ms-animation: slide 1s ease 3.5s forwards;
    animation: slide 1s ease 3.5s forwards;
  }




  @media (max-width: 991px) {
    //padding: 16px;
    .container {
      padding: 0;
    }
  }
  @media (max-width: 480px) {
    //padding: 8px 12px;
  }
}

.brand {

  @media (max-width: 580px) {
    margin-left: 20px;
  }
}

.initialHeader {
  background-color: none;
  color: $white;
  text-shadow:  0px 8px 18px rgba(0, 0, 0, 0.033);

  a {
    color: $white;
    text-shadow: 1px 0 0 $gray-dark;
  }

  .brand {
    color: $white;
  }

}


.lastHeader {
  background-color: $white;
  color: $gray-dark;

  a {
    color: $gray-dark;
    text-shadow: none;
  }

  .brand {
    color: $gray-dark;
  }

}


@keyframes slideUp {
  0% {
    transform: translateY(30px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }

  10%,
  20% {
    transform: scale3d(0.8, 0.66, 0.66) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.tada {
  animation-name: tada;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.dropdown-menu {
  padding: 0;
  min-width: 1rem !important;
}
.dropdown-item {
  padding: 2px 5px !important;
}
